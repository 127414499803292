import styles from './Feature.module.scss';

import DOMPurify from 'isomorphic-dompurify';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import typeFeature from '@/utilities/types/Feature';
import typeFeatures from '@/utilities/types/Features';
import { Fragment } from 'react';

const Buttons = dynamic(() => import('@/components/molecules/Buttons'));
const Image = dynamic(() => import('next/image'));
const Note = dynamic(() => import('@/components/molecules/Note'));
const Typography = dynamic(() => import('@/components/atoms/Typography'));

interface FeatureProps {
  className?: string;
  customise: typeFeatures['customise'];
  feature: typeFeature[];
  stretch?: boolean;
}

const Feature = (props: FeatureProps) => {
  const { className, customise, feature, stretch } = props;

  return (
    <div
      className={cn(
        className,
        customise.hollow
          ? styles[customise.border.border]
          : styles[customise.background.background],
        stretch && styles.stretch,
        styles.root,
      )}
    >
      {feature.map((feature, index) => (
        <Fragment key={index}>
          {feature.editor && (
            <Typography className={styles.editor} xs={3}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(feature.editor),
                }}
              />
            </Typography>
          )}
          {feature.icon && (
            <i className={styles.icon}>
              <Image
                alt={feature.icon.altText}
                className={styles.cover}
                height={feature.icon.mediaDetails.height || 44}
                src={feature.icon.mediaItemUrl}
                width={feature.icon.mediaDetails.width || 44}
              />
            </i>
          )}
          {feature.links && (
            <Buttons buttons={feature.links} className={styles.links} />
          )}
          {feature.note && <Note className={styles.note} note={feature.note} />}
          {feature.title && (
            <Typography
              className={styles.title}
              family="poppins"
              tag="h3"
              xs={4}
              md={5}
            >
              {feature.title}
            </Typography>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Feature;
